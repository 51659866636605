import { memo } from 'react'

export default memo(function JustCallLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width={width}
            height={height}
        >
            <path
                className="st0"
                fill="#85FFCD"
                d="m12.86 27.61-9.41 8.75c-1.33 1.24-1.33 3.47 0 4.71l9.41 8.7"
            />
            <path
                className="st1"
                fill="#494949"
                d="m87.14 63.55 9.41-8.75c1.33-1.24 1.33-3.47 0-4.71l-9.41-8.7M62.37 14.17c-.99 0-1.93.2-2.89.31 1.79 3.71 2.89 7.84 2.89 12.31v32.22c0 13.73-9.58 24.93-21.88 26.48C44.62 94.08 52.82 100 62.37 100c13.68 0 24.77-12.01 24.77-26.8V40.98c0-14.8-11.09-26.81-24.77-26.81z"
            />
            <path
                className="st0"
                fill="#85FFCD"
                d="M37.63 73.21V40.99c0-13.73 9.58-24.93 21.88-26.48C55.38 5.92 47.18 0 37.63 0 23.95 0 12.86 12.01 12.86 26.8v32.22c0 14.8 11.09 26.8 24.77 26.8.98 0 1.93-.2 2.87-.31-1.77-3.7-2.87-7.83-2.87-12.3z"
            />
        </svg>
    )
})
