import { Text } from '@teamflow/design'
import { HuddleLogoIcon } from '@teamflow/ui-icons'

import type { ReactNode } from 'react'

// NOTE: the styles for this component are inlined in a style tag in `_document.tsx`
// in order to prevent moments where the stylesheet is still loading in and the loader
// renders without styles
export default function HuddleLoader({
    children,
    size = 96,
    marginTop = true,
}: {
    children?: ReactNode
    size?: number
    marginTop?: boolean
}) {
    return (
        <div className="spinning-loader-wrapper">
            <div
                className="spinning-loader"
                style={{ marginTop: marginTop ? '30px' : '0' }}
                data-testid="tf.common.loading-spinner"
            >
                <HuddleLogoIcon width={size} height={size} />
            </div>
            {children && (
                <div className="spinning-loader-text-wrapper">
                    <Text weight="400" size="textSize24">
                        {children}
                    </Text>
                </div>
            )}
        </div>
    )
}
