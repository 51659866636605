import { memo } from 'react'

export default memo(function SearchIcon({
    color = '#A4AEB1',
    size = 18,
}: {
    color?: string
    size?: number
}) {
    return (
        <svg
            width={size}
            height={size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
        >
            <path
                d="M17.72 16.375l-4.986-4.988A7.094 7.094 0 006.851.004a7.09 7.09 0 00-6.847 6.85 7.094 7.094 0 006.37 7.295 7.09 7.09 0 005.01-1.412l4.986 4.988a.967.967 0 001.35 0 .956.956 0 000-1.35zM1.962 7.116A5.158 5.158 0 018.122 2.06a5.155 5.155 0 013.758 7.03 5.156 5.156 0 01-4.763 3.184A5.161 5.161 0 011.96 7.116z"
                fill={color}
            />
        </svg>
    )
})
