import { memo } from 'react'

export default memo(function AircallLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width={width}
            height={height}
        >
            <path
                d="M94.62 24.62C92.44 13.33 84.16 4.48 73.7 2.15 68.41.87 59.81 0 50 0S31.59.87 26.3 2.15C15.84 4.54 7.56 13.39 5.38 24.62 4.18 30.27 3.36 39.52 3.36 50s.82 19.73 2.02 25.38C7.56 86.61 15.84 95.46 26.3 97.85 31.59 99.13 40.19 100 50 100s18.41-.87 23.7-2.15c10.46-2.39 18.74-11.23 20.92-22.47 1.2-5.65 2.02-14.9 2.02-25.38s-.82-19.73-2.02-25.38zM73.38 71.15v.35c0 1.34-.82 2.5-1.91 2.97-1.69.64-4.52 1.16-8.17 1.57-.16 0-.33.06-.6.06-.65.12-1.36-.35-1.63-1.11-.87-2.27-2.78-3.96-5.07-4.48-1.36-.35-3.6-.58-6.16-.58s-4.79.23-6.16.58c-2.29.52-4.14 2.21-5.01 4.42-.33.76-1.04 1.22-1.85 1.16-.22 0-.38-.06-.49-.06-3.65-.41-6.54-.93-8.23-1.57-1.13-.46-1.9-1.62-1.9-2.96V71.03c.27-4.07 2.62-13.62 6.05-24.51 3.92-12.46 8.06-22.53 9.92-24.51.27-.35.65-.58 1.04-.81.16-.12.33-.17.54-.23 1.2-.47 3.49-.76 6.05-.76 2.56 0 4.85.29 6.05.76.22.06.44.17.54.23.38.23.71.47 1.03.81 1.85 1.98 5.99 12.05 9.92 24.51 3.43 10.88 5.77 20.43 6.05 24.51-.01.06-.01.06-.01.12z"
                fill="#03b388"
            />
        </svg>
    )
})
