import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export default memo(function MainFloorIcon({
    width = 17,
    height = 16,
    color = '#636E72',
}: IconProps): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 17 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.037 14.795a6.488 6.488 0 01-2.672-3.128H7.72a.667.667 0 100-1.334H5.93A10 10 0 015.662 8c0-.671.065-1.341.196-2 7.343 0 9.164.005 9.657-.012a.628.628 0 00.595-.917A7.964 7.964 0 008.623 0C3.615.324.913 4.06.683 7.44A8.008 8.008 0 008.663 16h.004a.656.656 0 00.638-.54.631.631 0 00-.268-.665zm-4.47-4.462h-2.14A6.592 6.592 0 012.305 6h2.19a11.883 11.883 0 00.071 4.333zm4.096-8.887a6.774 6.774 0 012.428 3.22H6.234a6.699 6.699 0 012.429-3.22zm5.777 3.22h-1.938a8.656 8.656 0 00-1.6-2.946 6.606 6.606 0 013.538 2.947zM6.405 1.734a8.6 8.6 0 00-1.58 2.934H2.9a6.694 6.694 0 013.506-2.934zm-3.303 9.934H4.93a8.477 8.477 0 001.476 2.6 6.693 6.693 0 01-3.303-2.6z"
                fill={color}
            />
            <path
                d="M13.053 7.333a3.337 3.337 0 00-3.334 3.334c0 2.114 2.972 5.109 3.098 5.236a.333.333 0 00.471 0c.126-.127 3.098-3.122 3.098-5.236a3.337 3.337 0 00-3.333-3.334zm0 2.167a1.167 1.167 0 110 2.336 1.167 1.167 0 010-2.337V9.5z"
                fill={color}
            />
        </svg>
    )
})
