import { memo } from 'react'

export default memo(function InviteEmptyTeamIcon() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity=".5"
                d="M11.875 17.5a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25zM28.125 17.5a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25zM11.875 33.75a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25z"
                fill="#B16EEF"
            />
            <path
                d="M11.875 17.5a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25zM28.125 17.5a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25zM11.875 33.75a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25zM28.125 23.75v8.75M32.5 28.125h-8.75"
                stroke="#232323"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})
