import React from 'react'

const PacketLossIcon = React.memo(
    ({ width = 16, height = 16 }: { width?: number; height?: number }) => (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.026 12.692a.138.138 0 0 0-.01-.143 13.353 13.353 0 0 1-.82-1.29.14.14 0 0 1 .123-.203H7.5a.555.555 0 0 0 .555-.506.546.546 0 0 0-.551-.604H5.829a.136.136 0 0 1-.135-.105 8.277 8.277 0 0 1-.197-1.84c-.001-.522.046-1.041.14-1.554a.144.144 0 0 1 .143-.113c6.05 0 7.53.004 7.927-.01a.518.518 0 0 0 .5-.74 6.634 6.634 0 0 0-6.246-4.25 6.79 6.79 0 0 0-4.613 1.961 6.801 6.801 0 0 0-2.014 4.594 6.65 6.65 0 0 0 5.19 6.611.139.139 0 0 0 .163-.18c-.111-.329-.113-.742.34-1.628ZM10.18 3a.14.14 0 0 1 .167-.037 5.497 5.497 0 0 1 2.331 2.044.14.14 0 0 1 .005.144.139.139 0 0 1-.124.071h-1.267a.14.14 0 0 1-.132-.095 7.3 7.3 0 0 0-.986-1.956.14.14 0 0 1 .006-.17ZM6.047 5.03a5.59 5.59 0 0 1 1.865-2.434.14.14 0 0 1 .162 0A5.649 5.649 0 0 1 9.939 5.03a.139.139 0 0 1-.128.191H6.175a.137.137 0 0 1-.115-.061.14.14 0 0 1-.013-.13Zm-2.72-.021a5.584 5.584 0 0 1 2.3-2.025.139.139 0 0 1 .172.206 7.291 7.291 0 0 0-.97 1.937.139.139 0 0 1-.13.095H3.441a.139.139 0 0 1-.117-.213h.002Zm-.433 4.936a.14.14 0 0 1-.131-.093 5.543 5.543 0 0 1-.091-3.418.14.14 0 0 1 .13-.1h1.559a.14.14 0 0 1 .137.16 9.948 9.948 0 0 0 .052 3.284.139.139 0 0 1-.136.167h-1.52ZM5.814 13a.139.139 0 0 1-.166.037 5.539 5.539 0 0 1-2.141-1.76.14.14 0 0 1 .112-.22h1.178a.139.139 0 0 1 .13.09c.223.6.524 1.166.894 1.686a.14.14 0 0 1-.006.167Z"
                fill="#A4AEB1"
            />
            <path
                d="M11.77 8.15a.804.804 0 0 0-1.421 0L7.542 13.5a.796.796 0 0 0 .32 1.064c.12.067.254.102.391.103h5.613a.805.805 0 0 0 .683-.38.793.793 0 0 0 .027-.784L11.77 8.15Zm-.71 1.653a.416.416 0 0 1 .415.417v1.667a.417.417 0 1 1-.832 0v-1.665a.417.417 0 0 1 .416-.419Zm0 3.89a.555.555 0 1 1 0-1.111.555.555 0 0 1 0 1.11Z"
                fill="#EB5757"
            />
        </svg>
    )
)

export default PacketLossIcon
