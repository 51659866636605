import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export default memo(function OpenDoorIcon({
    width,
    height,
    color = '#636E72',
}: IconProps): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 11 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10.282 1.167H5.957V.61A.27.27 0 005.85.395.293.293 0 005.607.34L.417 1.45a.29.29 0 00-.162.097.272.272 0 00-.063.174v10.556c0 .063.022.124.063.174a.29.29 0 00.163.097l5.189 1.111a.3.3 0 00.243-.054.28.28 0 00.107-.217v-.555h4.325c.153 0 .3-.06.407-.163a.545.545 0 00.17-.393V1.722a.545.545 0 00-.17-.393.588.588 0 00-.407-.162zm-5.766 6.11c0 .166-.05.327-.146.464a.859.859 0 01-.388.307.895.895 0 01-.942-.18.805.805 0 01-.187-.908.84.84 0 01.318-.375.888.888 0 011.092.104.818.818 0 01.253.589zm4.9 4.445h-3.17a.294.294 0 01-.204-.081.273.273 0 01-.085-.196v-8.89c0-.073.03-.144.085-.196a.294.294 0 01.204-.081h3.17c.077 0 .15.029.205.081a.273.273 0 01.084.197v8.889c0 .073-.03.144-.084.196a.294.294 0 01-.204.081z" />
        </svg>
    )
})
