import { memo } from 'react'

export default memo(function RingCentralLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            height={height}
            width={width}
        >
            <path
                d="M34.05 46.99v1.33h-.12c-.53-.93-1.62-1.58-3.17-1.58-2.28 0-4.33 1.8-4.33 4.98s2.02 5 4.33 5c1.5 0 2.38-.62 3.05-1.63h.1v2.05c0 1.1-.6 1.6-1.6 1.6h-4.77v2.08h4.72c2.62 0 4-1.47 4-3.85v-9.98h-2.21zm-2.63 7.6c-1.57 0-2.55-.97-2.55-2.87s.97-2.88 2.55-2.88c1.52 0 2.53.97 2.53 2.87 0 1.89-1.02 2.88-2.53 2.88zm-13.1 2.11v-5.63c0-1.38.85-2.28 2.23-2.28 1.32 0 2.1.77 2.1 2.25v5.65H25v-6.02c0-2.45-1.18-3.93-3.62-3.93-1.48 0-2.62.67-3.13 1.58h-.08v-1.33h-2.2v9.72h2.35zm-4.34-9.71h-2.35v9.72h2.35v-9.72zM7.55 50v-.1c1.43-.4 2.42-1.37 2.42-3.53 0-2.23-1.67-3.85-4.73-3.85H0v14.17h2.43v-5.63h2.95c1.38 0 2.07.68 2.07 2.07v3.57h2.43v-3.57c0-1.99-.85-2.81-2.33-3.13zm-2.47-1.11H2.42v-4.17h2.67c1.32 0 2.33.55 2.33 2.08s-1.02 2.09-2.34 2.09z"
                fill="#1085bc"
            />
            <path
                d="M100 41.72h-2.35V56.7H100V41.72zm-8.23 5.02c-2.17 0-3.85 1.05-4.13 3.2h2.3c.17-.82.77-1.3 1.82-1.3 1.23 0 1.8.7 1.8 1.82v.5h-2.37c-2.18 0-3.8.97-3.8 2.95 0 1.98 1.32 3.02 3.22 3.02 1.48 0 2.45-.63 2.98-1.63h.1v1.38h2.23v-6.15c0-2.36-1.22-3.79-4.15-3.79zm-.49 8.4c-.92 0-1.48-.45-1.48-1.18 0-.85.6-1.3 1.7-1.3h2.05l.02.13c0 1.48-.85 2.35-2.29 2.35zm-8.05 1.56v-5.03c0-1.5.93-2.45 2.42-2.45h.92v-2.23h-.95c-1.12 0-1.92.42-2.43 1.38h-.09v-1.38h-2.2v9.72h2.33zm-3.81-2.15v2.15h-1.6c-2.25 0-3.48-1.05-3.48-3.28v-4.5h-1.6v-1.93h1.6V44.4h2.35v2.58h2.73v1.93h-2.73v4.23c0 .88.53 1.42 1.42 1.42h1.32v-.01zM65.03 56.7v-5.63c0-1.38.85-2.28 2.23-2.28 1.32 0 2.1.77 2.1 2.25v5.65h2.35v-6.02c0-2.45-1.18-3.93-3.62-3.93-1.48 0-2.62.67-3.12 1.58h-.07v-1.33h-2.2v9.72h2.33zm-8.61-1.68c-1.48 0-2.47-1-2.52-2.55h7.18l-.02-.77c0-3.25-2.1-4.97-4.68-4.97-2.78 0-4.8 1.98-4.8 5.1 0 3.12 1.88 5.1 4.8 5.1 2.45 0 4.05-1.3 4.52-3.2h-2.28c-.24.76-.85 1.29-2.2 1.29zm-.02-6.33c1.53 0 2.45.87 2.52 2.08H53.9c.22-1.23.97-2.08 2.5-2.08zm-11.83 8.26c3.5 0 5.48-1.95 5.95-4.83h-2.38c-.35 1.32-1.32 2.53-3.57 2.53-2.33 0-4.18-1.58-4.18-5.07 0-3.47 1.87-5.05 4.18-5.05 2.33 0 3.27 1.22 3.57 2.52h2.38c-.38-2.88-2.33-4.77-5.95-4.77-3.88 0-6.7 2.72-6.7 7.33 0 4.66 2.81 7.36 6.7 7.34zM12.82 45.42c.32 0 .62-.08.87-.27.25-.17.45-.42.58-.7.12-.28.15-.6.08-.9-.07-.3-.22-.58-.43-.8-.22-.22-.5-.37-.8-.43-.3-.07-.62-.03-.9.08a1.594 1.594 0 0 0-.97 1.45c0 .32.08.62.27.87.17.25.42.45.7.57.18.1.4.13.6.13zm-1.8-5.88c-.57.23-1.08.58-1.52 1.02l1.1 1.1c.28-.28.63-.52 1.02-.68a3.243 3.243 0 0 1 2.4 0c.38.15.72.38 1.02.67l1.1-1.1c-.65-.65-1.48-1.1-2.4-1.28-.92-.17-1.86-.08-2.72.27z"
                fill="#f47a20"
            />
        </svg>
    )
})
