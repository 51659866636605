import { getPublicUrl } from '@teamflow/lib'

export default function SlackIcon({
    size = '20px',
    mr = 0,
}: {
    size?: number | string
    mr?: number | string
}) {
    return (
        <img
            src={getPublicUrl('/icons/slack.png')}
            width={size}
            height={size}
            style={{ marginRight: mr }}
        />
    )
}
