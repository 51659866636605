import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export const JustCallIcon = memo(({ width = 130, height = 21 }: IconProps) => (
    <svg
        width={width}
        height={height}
        fill="none"
        style={{ display: 'inline', transform: 'scale(0.8)' }}
    >
        <path
            d="M36.569 0v12.97c0 2.648-1.148 3.416-2.811 3.416l-.328-.014c-1.881-.138-2.248-1.554-2.311-2.443-.003-.017-.017-.02-.02-.037v-.086c-.016-.347 0-.605 0-.605h-3.866v.54c.007.356.06.85.15 1.391.108.516.24 1.012.456 1.449.062.139.132.271.211.397 1.062 1.785 3.04 2.827 5.711 2.827 4.17 0 6.74-1.852 6.74-7.229V0h-3.932Z"
            fill="#1D1D1B"
        />
        <path
            d="M2.253 5.553.216 7.303a.622.622 0 0 0 0 .942l2.037 1.739"
            fill="#85FFCD"
        />
        <path
            d="m18.327 12.738 2.037-1.75a.622.622 0 0 0 0-.942l-2.037-1.74m-5.362-5.439c-.215 0-.417.04-.625.063.387.74.625 1.567.625 2.46v6.442c0 2.745-2.073 4.984-4.736 5.295.893 1.716 2.67 2.9 4.736 2.9a5.36 5.36 0 0 0 5.36-5.357V8.228c0-2.96-2.4-5.36-5.36-5.36Z"
            fill="#231F20"
        />
        <path
            d="M7.61 14.67V8.228c0-2.745 2.074-4.984 4.736-5.295-.893-1.716-2.669-2.9-4.736-2.9A5.36 5.36 0 0 0 2.25 5.39v6.442c0 2.96 2.4 5.357 5.36 5.357.212 0 .417-.04.622-.062a5.297 5.297 0 0 1-.621-2.457Z"
            fill="#85FFCD"
        />
        <path
            d="M52.505 10.924c0 3.897-1.719 6.01-3.591 6.01-1.312 0-2.248-.675-2.248-2.437v-9.47h-3.871V15.58c0 2.96 2.03 4.456 4.841 4.456 2.03 0 3.714-1.056 4.869-2.61v2.61h3.87V5.03h-3.87v5.894Zm11.967-.211c-.905-.317-1.45-.78-1.45-1.416 0-.81.663-1.211 1.387-1.211.845 0 1.84.401 2.536 1.269l2.233-1.964c-.935-1.412-2.718-2.365-4.77-2.365-2.955 0-4.857 1.991-4.857 4.244 0 2.253 1.42 3.26 3.078 3.923 1.84.752 3.8 1.011 3.8 2.223 0 1.065-.722 1.558-1.872 1.558-1.48 0-2.506-.98-3.048-2.396L58.52 16.11c.753 1.79 2.867 3.927 6.037 3.927 3.29 0 5.4-2.223 5.4-4.85.007-3.117-3.405-3.753-5.485-4.474Zm13.813 6.275c-1.306 0-2.05-.575-2.05-2.36V8.357h4.132V5.308h-4.133V.023H72.38v5.285h-2.425v3.049h2.425v6.328c0 3.597 2.208 5.352 5.749 5.352.84 0 1.677-.144 2.548-.518l-.902-2.879a3.113 3.113 0 0 1-1.49.348ZM91.293 4.022c1.002 0 1.85.222 2.618.542.276.098.562.197.784.357.848.529 1.447 1.306 1.831 2.254l3.45-.83C99.003 2.813 95.994.739 91.659.739c-5.068-.056-9.639 3.476-9.551 9.647-.088 6.175 4.483 9.706 9.551 9.65 4.335 0 7.34-2.077 8.317-5.605l-3.447-.84c-.373.925-.935 1.699-1.75 2.228-.269.193-.602.323-.949.431a6.73 6.73 0 0 1-2.54.507c-3.346.035-5.655-2.294-5.595-6.368-.057-4.073 2.252-6.403 5.598-6.367Zm16.63 12.888c-2.471 0-3.952-1.947-3.952-4.396 0-2.417 1.481-4.364 3.952-4.364 2.533 0 4.052 1.886 4.052 4.364 0 2.507-1.519 4.396-4.052 4.396Zm4.439-9.643c-1.042-1.8-2.831-2.24-5.155-2.24-3.874 0-6.517 3.33-6.517 7.49 0 4.157 2.643 7.52 6.517 7.52 2.324 0 4.113-.42 5.155-2.22v1.923h3.339V5.286h-3.339v1.98Zm9.774-7.244h-3.574v20.014h3.574V.023Zm7.147 0h-3.574v20.014h3.574V.023Z"
            fill="#1D1D1B"
        />
    </svg>
))
