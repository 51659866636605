import { IconProps } from '@teamflow/types'

export default function HourglassIcon(props: IconProps) {
    const { width = 14, height = 14, color = 'white' } = props

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.958 12.833h-.583v-2.041A4.377 4.377 0 009.18 7a4.375 4.375 0 002.194-3.792V1.167h.583a.583.583 0 000-1.167H2.042a.583.583 0 100 1.167h.583v2.041A4.375 4.375 0 004.819 7a4.378 4.378 0 00-2.194 3.792v2.041h-.583a.583.583 0 000 1.167h9.916a.583.583 0 000-1.167zm-8.166-2.041a3.212 3.212 0 013.53-3.193 3.306 3.306 0 012.886 3.337v1.606a.292.292 0 01-.291.291H4.083a.292.292 0 01-.291-.291v-1.75zm.291-9.625h5.834a.292.292 0 01.291.291v1.608a3.306 3.306 0 01-2.887 3.336 3.212 3.212 0 01-3.53-3.194v-1.75a.292.292 0 01.292-.291z"
                fill={color}
            />
            <path
                d="M7 5.615a2.358 2.358 0 002.003-1.263.292.292 0 00-.253-.438h-3.5a.292.292 0 00-.253.438A2.358 2.358 0 007 5.615zM7.436 9.094a.604.604 0 00-.875 0L4.623 11.28a.583.583 0 00.435.97h3.884a.584.584 0 00.435-.97l-1.94-2.186z"
                fill={color}
            />
        </svg>
    )
}
