import React from 'react'

import { IconProps } from '@teamflow/types'

export default React.memo(function AlertIcon({
    width = 12,
    height = 12,
}: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            width={width}
            height={height}
        >
            <path
                fillRule="evenodd"
                d="M4.855.708c.5-.896 1.79-.896 2.29 0l4.675 8.351a1.312 1.312 0 01-1.146 1.954H1.33A1.312 1.312 0 01.183 9.058L4.855.708zM7 7V3H5v4h2zm-1 3a1 1 0 100-2 1 1 0 000 2z"
            />
        </svg>
    )
})
