import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export default memo(function VideoBlockedIcon({ width, height }: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.98398 7.06072C3.95424 7.03074 3.91628 7.01061 3.87508 7.00295C3.83388 6.9953 3.79137 7.00048 3.75312 7.01782C3.5347 7.11743 3.34927 7.27911 3.2192 7.48338C3.08912 7.68766 3.01995 7.92581 3.02002 8.16914V15.738C3.02002 16.0726 3.15078 16.3935 3.38354 16.63C3.6163 16.8666 3.93199 16.9995 4.26117 16.9995H13.2628C13.3038 16.9996 13.3438 16.9873 13.3779 16.9642C13.412 16.9411 13.4386 16.9082 13.4543 16.8698C13.4699 16.8313 13.474 16.789 13.466 16.7481C13.458 16.7073 13.4383 16.6698 13.4092 16.6404L3.98398 7.06072Z"
                fill="#EB5757"
                opacity="0.25"
            />
            <path
                d="M15.023 14.8558C15.0046 14.8364 14.99 14.8134 14.9801 14.7881C14.9702 14.7628 14.9651 14.7356 14.9652 14.7082V8.26148C14.9652 7.92978 14.84 7.61167 14.6172 7.37713C14.3944 7.14258 14.0922 7.01082 13.7771 7.01082H7.6527C7.62667 7.01091 7.60089 7.00558 7.57682 6.99514C7.55276 6.98469 7.5309 6.96933 7.5125 6.94995L2.85191 2.04403C2.72179 1.90716 2.55046 1.82224 2.36734 1.80383C2.18422 1.78542 2.00073 1.83467 1.84835 1.94314C1.80653 1.97273 1.76753 2.00652 1.73191 2.04403C1.58342 2.20038 1.5 2.41242 1.5 2.63351C1.5 2.85459 1.58342 3.06663 1.73191 3.22298L19.1577 21.566C19.307 21.7179 19.5071 21.8019 19.7148 21.8C19.9225 21.7981 20.1211 21.7104 20.268 21.5558C20.4149 21.4013 20.4982 21.1921 20.5 20.9735C20.5018 20.7549 20.4219 20.5443 20.2777 20.387L15.023 14.8558Z"
                fill="#EB5757"
                opacity="0.25"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9066 7.56443C21.2802 7.71921 21.5239 8.08385 21.5239 8.48831V15.155C21.5239 15.5594 21.2802 15.9241 20.9066 16.0789C20.5329 16.2336 20.1028 16.1481 19.8168 15.8621L16.4834 12.5288C16.0929 12.1382 16.0929 11.5051 16.4834 11.1145L19.8168 7.7812C20.1028 7.49521 20.5329 7.40965 20.9066 7.56443Z"
                fill="#EB5757"
                opacity="0.25"
            />
        </svg>
    )
})
