import { memo } from 'react'

export default memo(function DialpadLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29 22"
            width={width}
            height={height}
        >
            <path
                d="M21.1355 3.81281C19.4319 3.81113 17.7852 4.43774 16.4989 5.57712C15.2126 6.7165 14.3735 8.29182 14.1363 10.0126V0.108626C14.1364 0.0869409 14.1301 0.0657361 14.1182 0.0477565C14.1063 0.029777 14.0894 0.0158506 14.0697 0.00778021C14.05 -0.000290209 14.0283 -0.00213304 14.0075 0.00249027C13.9868 0.00711357 13.9678 0.0179901 13.9532 0.033712L9.81917 4.37782C8.94818 4.00357 8.01241 3.81144 7.06729 3.81281C3.16599 3.81281 0 7.0305 0 11C0 14.9696 3.16422 18.1873 7.06817 18.1873C8.77163 18.189 10.4183 17.5625 11.7045 16.4233C12.9908 15.2841 13.83 13.709 14.0673 11.9884V21.8914C14.0673 21.9889 14.1832 22.0359 14.2496 21.9664L18.3845 17.6223C19.2552 17.9964 20.1907 18.1885 21.1355 18.1873C25.0386 18.1873 28.2028 14.9696 28.2028 11C28.2028 7.0305 25.0386 3.81281 21.1355 3.81281Z"
                fill="#7C52FF"
            ></path>
        </svg>
    )
})
