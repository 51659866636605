import { memo } from 'react'

export default memo(function ZoomLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            height={height}
            width={width}
        >
            <g id="Layer_x0020_1">
                <g id="_2116467169744">
                    <path
                        d="M50 0c27.61 0 50 22.39 50 50s-22.39 50-50 50S0 77.61 0 50 22.39 0 50 0z"
                        fill="#e5e5e4"
                    />
                    <path
                        className="st1"
                        fill="#fff"
                        d="M50 .97C77.08.97 99.03 22.92 99.03 50S77.08 99.03 50 99.03.97 77.08.97 50 22.92.97 50 .97z"
                    />
                    <path
                        d="M50 4.91C74.9 4.91 95.09 25.1 95.09 50S74.9 95.09 50 95.09 4.91 74.9 4.91 50 25.1 4.91 50 4.91z"
                        fill="#5e84c3"
                    />
                    <path
                        fill="#fff"
                        d="M20.58 35.87v21.19c.02 4.79 3.93 8.65 8.71 8.63h30.89c.88 0 1.58-.71 1.58-1.57V42.94c-.02-4.79-3.93-8.65-8.71-8.63H22.17c-.88 0-1.59.7-1.59 1.56zm43.15 8.27 12.75-9.32c1.11-.92 1.97-.69 1.97.97V64.2c0 1.89-1.05 1.66-1.97.97l-12.75-9.3V44.14z"
                    />
                </g>
            </g>
        </svg>
    )
})
