import { Spritesheet } from '@teamflow/types'

const getDockSpriteSheetNameWithCommitSuffix = (
    version: '1x' | '2x' | '3x'
) => {
    return `/images/dock/spritesheet@${version}.png`
}

const DOCK_SPRITESHEET: Spritesheet = {
    '1x': {
        frames: {
            'chat.png': {
                frame: {
                    x: 1,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'collapse.png': {
                frame: {
                    x: 51,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'expand.png': {
                frame: {
                    x: 101,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'fullscreen-off.png': {
                frame: {
                    x: 151,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'fullscreen-on.png': {
                frame: {
                    x: 1,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'hangup.png': {
                frame: {
                    x: 51,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'home.png': {
                frame: {
                    x: 101,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'image.png': {
                frame: {
                    x: 151,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'leave.png': {
                frame: {
                    x: 1,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'meeting-recording-off.png': {
                frame: {
                    x: 51,
                    y: 101,
                    w: 18,
                    h: 18,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 18,
                    h: 18,
                },
                sourceSize: {
                    w: 18,
                    h: 18,
                },
            },
            'meeting-recording-on.png': {
                frame: {
                    x: 71,
                    y: 101,
                    w: 18,
                    h: 18,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 18,
                    h: 18,
                },
                sourceSize: {
                    w: 18,
                    h: 18,
                },
            },
            'mic-off.png': {
                frame: {
                    x: 91,
                    y: 101,
                    w: 22,
                    h: 23,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 22,
                    h: 23,
                },
                sourceSize: {
                    w: 22,
                    h: 23,
                },
            },
            'mic-on.png': {
                frame: {
                    x: 201,
                    y: 1,
                    w: 14,
                    h: 20,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 14,
                    h: 20,
                },
                sourceSize: {
                    w: 14,
                    h: 20,
                },
            },
            'more.png': {
                frame: {
                    x: 115,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'pip-off.png': {
                frame: {
                    x: 165,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'pip-on.png': {
                frame: {
                    x: 1,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'scratchpad.png': {
                frame: {
                    x: 51,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'screenshare-off.png': {
                frame: {
                    x: 101,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'screenshare-on.png': {
                frame: {
                    x: 151,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'shared-browser.png': {
                frame: {
                    x: 217,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'timer.png': {
                frame: {
                    x: 201,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'video-off.png': {
                frame: {
                    x: 215,
                    y: 101,
                    w: 20,
                    h: 20,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 20,
                    h: 20,
                },
                sourceSize: {
                    w: 20,
                    h: 20,
                },
            },
            'video-on.png': {
                frame: {
                    x: 237,
                    y: 101,
                    w: 21,
                    h: 20,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 21,
                    h: 20,
                },
                sourceSize: {
                    w: 21,
                    h: 20,
                },
            },
            'whiteboard.png': {
                frame: {
                    x: 215,
                    y: 123,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
        },
        meta: {
            app: 'http://www.codeandweb.com/texturepacker',
            version: '1.0',
            image: getDockSpriteSheetNameWithCommitSuffix('1x'),
            format: 'RGBA8888',
            size: {
                w: 266,
                h: 200,
            },
        },
    },
    '2x': {
        frames: {
            'chat.png': {
                frame: {
                    x: 1,
                    y: 1,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'collapse.png': {
                frame: {
                    x: 99,
                    y: 1,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'expand.png': {
                frame: {
                    x: 197,
                    y: 1,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'fullscreen-off.png': {
                frame: {
                    x: 295,
                    y: 1,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'fullscreen-on.png': {
                frame: {
                    x: 1,
                    y: 99,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'hangup.png': {
                frame: {
                    x: 99,
                    y: 99,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'home.png': {
                frame: {
                    x: 197,
                    y: 99,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'image.png': {
                frame: {
                    x: 295,
                    y: 99,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'leave.png': {
                frame: {
                    x: 1,
                    y: 197,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'meeting-recording-off.png': {
                frame: {
                    x: 99,
                    y: 197,
                    w: 36,
                    h: 36,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 36,
                    h: 36,
                },
                sourceSize: {
                    w: 36,
                    h: 36,
                },
            },
            'meeting-recording-on.png': {
                frame: {
                    x: 137,
                    y: 197,
                    w: 36,
                    h: 36,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 36,
                    h: 36,
                },
                sourceSize: {
                    w: 36,
                    h: 36,
                },
            },
            'mic-off.png': {
                frame: {
                    x: 175,
                    y: 197,
                    w: 44,
                    h: 46,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 44,
                    h: 46,
                },
                sourceSize: {
                    w: 44,
                    h: 46,
                },
            },
            'mic-on.png': {
                frame: {
                    x: 393,
                    y: 1,
                    w: 28,
                    h: 40,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 28,
                    h: 40,
                },
                sourceSize: {
                    w: 28,
                    h: 40,
                },
            },
            'more.png': {
                frame: {
                    x: 221,
                    y: 197,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'pip-off.png': {
                frame: {
                    x: 319,
                    y: 197,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'pip-on.png': {
                frame: {
                    x: 1,
                    y: 295,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'scratchpad.png': {
                frame: {
                    x: 99,
                    y: 295,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'screenshare-off.png': {
                frame: {
                    x: 197,
                    y: 295,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'screenshare-on.png': {
                frame: {
                    x: 295,
                    y: 295,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'shared-browser.png': {
                frame: {
                    x: 423,
                    y: 1,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'timer.png': {
                frame: {
                    x: 393,
                    y: 99,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
            'video-off.png': {
                frame: {
                    x: 417,
                    y: 197,
                    w: 40,
                    h: 40,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 40,
                    h: 40,
                },
                sourceSize: {
                    w: 40,
                    h: 40,
                },
            },
            'video-on.png': {
                frame: {
                    x: 459,
                    y: 197,
                    w: 41,
                    h: 40,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 41,
                    h: 40,
                },
                sourceSize: {
                    w: 41,
                    h: 40,
                },
            },
            'whiteboard.png': {
                frame: {
                    x: 417,
                    y: 239,
                    w: 96,
                    h: 96,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 96,
                    h: 96,
                },
                sourceSize: {
                    w: 96,
                    h: 96,
                },
            },
        },
        meta: {
            app: 'http://www.codeandweb.com/texturepacker',
            version: '1.0',
            image: getDockSpriteSheetNameWithCommitSuffix('2x'),
            format: 'RGBA8888',
            size: {
                w: 520,
                h: 392,
            },
        },
    },
    '3x': {
        frames: {
            'chat.png': {
                frame: {
                    x: 1,
                    y: 1,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'collapse.png': {
                frame: {
                    x: 147,
                    y: 1,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'expand.png': {
                frame: {
                    x: 293,
                    y: 1,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'fullscreen-off.png': {
                frame: {
                    x: 439,
                    y: 1,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'fullscreen-on.png': {
                frame: {
                    x: 1,
                    y: 147,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'hangup.png': {
                frame: {
                    x: 147,
                    y: 147,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'home.png': {
                frame: {
                    x: 293,
                    y: 147,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'image.png': {
                frame: {
                    x: 439,
                    y: 147,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'leave.png': {
                frame: {
                    x: 1,
                    y: 293,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'meeting-recording-off.png': {
                frame: {
                    x: 147,
                    y: 293,
                    w: 54,
                    h: 54,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 54,
                    h: 54,
                },
                sourceSize: {
                    w: 54,
                    h: 54,
                },
            },
            'meeting-recording-on.png': {
                frame: {
                    x: 203,
                    y: 293,
                    w: 54,
                    h: 54,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 54,
                    h: 54,
                },
                sourceSize: {
                    w: 54,
                    h: 54,
                },
            },
            'mic-off.png': {
                frame: {
                    x: 259,
                    y: 293,
                    w: 66,
                    h: 69,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 66,
                    h: 69,
                },
                sourceSize: {
                    w: 66,
                    h: 69,
                },
            },
            'mic-on.png': {
                frame: {
                    x: 585,
                    y: 1,
                    w: 42,
                    h: 60,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 42,
                    h: 60,
                },
                sourceSize: {
                    w: 42,
                    h: 60,
                },
            },
            'more.png': {
                frame: {
                    x: 327,
                    y: 293,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'pip-off.png': {
                frame: {
                    x: 473,
                    y: 293,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'pip-on.png': {
                frame: {
                    x: 1,
                    y: 439,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'scratchpad.png': {
                frame: {
                    x: 147,
                    y: 439,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'screenshare-off.png': {
                frame: {
                    x: 293,
                    y: 439,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'screenshare-on.png': {
                frame: {
                    x: 439,
                    y: 439,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'shared-browser.png': {
                frame: {
                    x: 629,
                    y: 1,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'timer.png': {
                frame: {
                    x: 585,
                    y: 147,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
            'video-off.png': {
                frame: {
                    x: 619,
                    y: 293,
                    w: 60,
                    h: 60,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 60,
                    h: 60,
                },
                sourceSize: {
                    w: 60,
                    h: 60,
                },
            },
            'video-on.png': {
                frame: {
                    x: 681,
                    y: 293,
                    w: 61,
                    h: 60,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 61,
                    h: 60,
                },
                sourceSize: {
                    w: 61,
                    h: 60,
                },
            },
            'whiteboard.png': {
                frame: {
                    x: 619,
                    y: 355,
                    w: 144,
                    h: 144,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 144,
                    h: 144,
                },
                sourceSize: {
                    w: 144,
                    h: 144,
                },
            },
        },
        meta: {
            app: 'http://www.codeandweb.com/texturepacker',
            version: '1.0',
            image: getDockSpriteSheetNameWithCommitSuffix('3x'),
            format: 'RGBA8888',
            size: {
                w: 774,
                h: 584,
            },
        },
    },
}
export default DOCK_SPRITESHEET
