import { memo } from 'react'

export default memo(function SoundOnIcon() {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.028 1.527 4.078 5.48a.167.167 0 0 1-.119.048H2.5a1.333 1.333 0 0 0-1.333 1.334v2.276A1.333 1.333 0 0 0 2.5 10.47h1.46c.044 0 .086.018.117.05l3.951 3.95A.666.666 0 0 0 9.167 14V2a.667.667 0 0 0-1.139-.473ZM12.89 3.31a.667.667 0 0 0-.943.943 5.296 5.296 0 0 1 0 7.493.667.667 0 0 0 .943.943 6.627 6.627 0 0 0 0-9.378Z"
                fill="#6e6e6e"
            />
            <path
                d="M11.257 5a.666.666 0 1 0-.944.94 2.92 2.92 0 0 1 0 4.119.668.668 0 1 0 .944.941 4.253 4.253 0 0 0 0-6Z"
                fill="#6e6e6e"
            />
        </svg>
    )
})
