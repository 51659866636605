import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export default memo(function MicBlockedIcon({
    width = 30,
    height = 30,
}: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5615 16.5008C18.4904 15.2382 18.9986 13.6896 19 12.0909C19 11.8498 18.9078 11.6186 18.7437 11.4481C18.5796 11.2776 18.3571 11.1818 18.125 11.1818C17.8929 11.1818 17.6704 11.2776 17.5063 11.4481C17.3422 11.6186 17.25 11.8498 17.25 12.0909C17.25 13.2228 16.9114 14.3185 16.2923 15.2317L17.5615 16.5008ZM15.2851 16.3457C14.3578 17.1186 13.2001 17.5455 12 17.5455C10.6076 17.5455 9.27226 16.9708 8.28769 15.9479C7.30312 14.9249 6.75 13.5375 6.75 12.0909C6.75 11.8498 6.65781 11.6186 6.49372 11.4481C6.32962 11.2776 6.10706 11.1818 5.875 11.1818C5.64294 11.1818 5.42038 11.2776 5.25628 11.4481C5.09219 11.6186 5 11.8498 5 12.0909C5.00155 13.8612 5.62451 15.5701 6.7521 16.8972C7.68493 17.9952 8.91014 18.7695 10.2627 19.1295C10.749 19.259 11.125 19.6764 11.125 20.1796C11.125 20.7331 10.6763 21.1818 10.1228 21.1818H9.375C9.14294 21.1818 8.92038 21.2776 8.75628 21.4481C8.59219 21.6186 8.5 21.8498 8.5 22.0909C8.5 22.332 8.59219 22.5632 8.75628 22.7337C8.92038 22.9042 9.14294 23 9.375 23H14.625C14.8571 23 15.0796 22.9042 15.2437 22.7337C15.4078 22.5632 15.5 22.332 15.5 22.0909C15.5 21.8498 15.4078 21.6186 15.2437 21.4481C15.0796 21.2776 14.8571 21.1818 14.625 21.1818H13.8772C13.3237 21.1818 12.875 20.7331 12.875 20.1796C12.875 19.6764 13.251 19.259 13.7373 19.1295C14.7747 18.8534 15.7372 18.3336 16.5503 17.6109L15.2851 16.3457ZM15.009 13.9483C15.3277 13.391 15.5 12.7502 15.5 12.0909V6.63636C15.5 5.67194 15.1313 4.74702 14.4749 4.06507C13.8185 3.38312 12.9283 3 12 3C11.0717 3 10.1815 3.38312 9.52513 4.06507C8.86875 4.74702 8.5 5.67194 8.5 6.63636V7.43934L15.009 13.9483ZM8.5 9.56066L14.0084 15.069C13.4238 15.4946 12.7235 15.7273 12 15.7273C11.0717 15.7273 10.1815 15.3442 9.52513 14.6622C8.86875 13.9803 8.5 13.0553 8.5 12.0909V9.56066Z"
                fill="#EB5757"
                opacity="0.25"
            />
            <path
                d="M3 1L23 21"
                stroke="#EB5757"
                opacity="0.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})
