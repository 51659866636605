import { Spritesheet } from '@teamflow/types'

const SEARCH_SPRITESHEET: Spritesheet = {
    '1x': {
        frames: {
            'search-icon-all-audiozones.png': {
                frame: {
                    x: 1,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-audiozone-create.png': {
                frame: {
                    x: 51,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-audiozone-goto.png': {
                frame: {
                    x: 101,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-camera.png': {
                frame: {
                    x: 151,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-chat.png': {
                frame: {
                    x: 201,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-contact.png': {
                frame: {
                    x: 1,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-download.png': {
                frame: {
                    x: 51,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-goto.png': {
                frame: {
                    x: 101,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-guest.png': {
                frame: {
                    x: 151,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-help.png': {
                frame: {
                    x: 201,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-image.png': {
                frame: {
                    x: 1,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-invite.png': {
                frame: {
                    x: 51,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-link.png': {
                frame: {
                    x: 101,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-meeting.png': {
                frame: {
                    x: 151,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-meeting-confirmed.png': {
                frame: {
                    x: 201,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-microphone.png': {
                frame: {
                    x: 1,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-notifications.png': {
                frame: {
                    x: 51,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-password.png': {
                frame: {
                    x: 101,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-place.png': {
                frame: {
                    x: 151,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-profile.png': {
                frame: {
                    x: 201,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-rename.png': {
                frame: {
                    x: 1,
                    y: 201,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-room.png': {
                frame: {
                    x: 51,
                    y: 201,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-settings.png': {
                frame: {
                    x: 101,
                    y: 201,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-signout.png': {
                frame: {
                    x: 151,
                    y: 201,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-status.png': {
                frame: {
                    x: 201,
                    y: 201,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-support.png': {
                frame: {
                    x: 251,
                    y: 1,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-teammates.png': {
                frame: {
                    x: 251,
                    y: 51,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'search-icon-upload.png': {
                frame: {
                    x: 251,
                    y: 101,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
            'toggle-audio.png': {
                frame: {
                    x: 251,
                    y: 151,
                    w: 48,
                    h: 48,
                },
                rotated: false,
                trimmed: false,
                spriteSourceSize: {
                    x: 0,
                    y: 0,
                    w: 48,
                    h: 48,
                },
                sourceSize: {
                    w: 48,
                    h: 48,
                },
            },
        },
        meta: {
            app: 'http://www.codeandweb.com/texturepacker',
            version: '1.0',
            image: '/images/search/spritesheet.png',
            format: 'RGBA8888',
            size: {
                w: 300,
                h: 250,
            },
        },
    },
}

export default SEARCH_SPRITESHEET
