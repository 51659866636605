import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export default memo(function MapIcon({
    width = 20,
    height = 20,
    color = '#636E72',
}: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.74951 11.4996L2.74951 12.4996V3.49957L6.74951 2.49957"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.75 13.4996L6.75 11.4996V2.49957L10.75 4.49957V13.4996Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.7505 4.4997L14.7505 3.49969V12.4997L10.7505 13.4997"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})
