import { memo } from 'react'

export default memo(function PhoneBurnerLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width={width}
            height={height}
        >
            <path
                d="M66.74 83.41c-1.47 1.13-3.24 2.01-5.12 2.54-.1.03-.2.06-.3.08-.23.06-.46.12-.7.16-2.93.62-6.02.35-8.52-1.2-1.07-.7-2.13-1.47-3.18-2.31-2.6-2.09-5.12-4.62-7.44-7.55-.76-.96-1.51-1.97-2.23-3.02-2.65-3.86-4.83-8.11-6.36-12.61-.55-1.62-1-3.24-1.35-4.82-.6-3.03.12-6.24 1.58-9.02.12-.22.24-.44.36-.65.05-.09.11-.19.16-.28 1.05-1.74 2.39-3.26 3.85-4.41.86-.66 2.09-.41 2.75.56l3.59 5.3 1.95 2.88c.66.97.29 2.46-.57 3.13-1.32 1.03-2.64 2.05-3.96 3.08-.59.46-.77 1.16-.48 2.03 1.31 3.65 3.03 6.99 5.21 10.12.43.62.84 1.19 1.25 1.72 1.74 2.27 3.38 3.89 5.41 5.66.68.55 1.36.6 1.95.14l3.97-3.07c.86-.67 2.31-.57 2.97.4 1.03 1.52 2.06 3.03 3.09 4.55.82 1.2 1.64 2.41 2.46 3.61.68.99.52 2.32-.34 2.98zm18.77-40.46c-1.45.77-3.02 1.52-4.55 2.07-5.33 1.92-10.23 1.5-8.25-8.07.28-1.34.69-2.87 1.26-4.6.55-1.9.59-3.62.29-5.16-.18-.89-.47-1.73-.84-2.5-1.01-2.09-2.63-3.76-4.23-5.01-2.5-1.96-4.95-2.92-4.95-2.92s.68 4.02-.32 7.25c-.41 1.32-1.1 2.51-2.24 3.24-1.42.9-3.53 1.09-6.64-.09-4.5-1.7-6.26-6.1-6.73-10.87C47.57 8.62 50.17 0 50.17 0c-1.23.62-2.39 1.47-3.48 2.33a32.427 32.427 0 0 0-6.48 6.99c-1.81 2.62-3.25 5.52-4.3 8.58-.55 1.6-.99 3.24-1.33 4.91-.33 1.6-.67 3.17-1.23 4.69-2.54 6.87-7.26 9.18-7.89 5.16-.14-.87-.09-2.03.22-3.5a11.536 11.536 0 0 0-3.93 5.04c-.49 1.21-.8 2.48-.97 3.77-.06.48-.11.96-.14 1.45-.13 1.91-.02 3.84.14 5.63.48 5.41 1.42 9.65-2.25 8.96-.98-.19-2.29-.72-4.03-1.69.21.37.41.76.59 1.16 1.51 3.32 2.25 7.77 3.02 12.64.24 1.53.59 6.1 2.5 11.74C21.89 81.63 23.87 85.87 27 90c3.9 4.12 8.43 6.6 12.86 8.07 9.93 3.29 19.28 1.37 19.6 1.34 4.51-.46 7.91-2.6 7.91-2.6s1.93-.96 3.18-1.96c1.55-1.24 2.39-1.96 3.6-3.18 2.85-2.87 4.48-7.74 5.4-11.81.78-3.48.33-11.64.19-17.77-.13-5.75 1.94-11.75 4.49-16.76.4-.82.83-1.62 1.28-2.38z"
                fill="#FD671A"
            />
        </svg>
    )
})
