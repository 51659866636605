import { recordAnimationIcon } from './MeetingRecordingIndicatorIcon.css'

export function MeetingRecordingIndicatorIcon() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid={'tf.icons.meeting-recording-indicator-icon'}
        >
            <circle cx="7" cy="7" r="6.5" stroke="#EB5757" />
            <circle
                cx="50%"
                cy="50%"
                r="2.5"
                fill="#EB5757"
                stroke="#EB5757"
                className={recordAnimationIcon}
            />
        </svg>
    )
}
