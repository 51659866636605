import { memo } from 'react'

import { IconProps } from '@teamflow/types'

export default memo(function Audio2Icon({
    width,
    height,
    color = '#636E72',
}: IconProps): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.04 2.3 6.12 8.21a.25.25 0 0 1-.18.07H3.75a2 2 0 0 0-2 2v3.42a2 2 0 0 0 2 2h2.19c.07 0 .13.02.18.07l5.92 5.92a1 1 0 0 0 1.71-.7V3a1 1 0 0 0-1.7-.7ZM19.34 4.97a1 1 0 0 0-1.42 1.41 7.94 7.94 0 0 1 0 11.24 1 1 0 0 0 1.41 1.41 9.94 9.94 0 0 0 0-14.06Z"
                fill={color}
            />
            <path
                d="M16.89 7.5a1 1 0 1 0-1.42 1.4 4.38 4.38 0 0 1 0 6.19 1 1 0 1 0 1.42 1.41 6.38 6.38 0 0 0 0-9Z"
                fill={color}
            />
        </svg>
    )
})
