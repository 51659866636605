import { memo } from 'react'

export default memo(function SalesLoftLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width={width}
            height={height}
        >
            <path
                d="M26.92 19.55c0-6.73 6.4-12.12 14.81-12.12 10.77 0 17.17 5.05 20.87 30.63h2.36l3.37-35.01c-24.25-8.41-53.87.68-54.2 25.59 0 27.26 43.42 27.26 43.42 52.17 0 7.41-6.4 12.12-13.8 12.12-19.19 0-23.56-16.16-24.57-32.99h-2.69l-6.4 31.64S24.23 100 39.71 100c18.51-.34 30.63-13.8 31.3-27.94 0-27.26-44.09-32.99-44.09-52.51z"
                fill="#08492e"
            />
            <path
                d="M72.92 91.5c0-4.4 3.52-8.5 8.5-8.5s8.5 3.81 8.5 8.5-3.52 8.5-8.5 8.5c-4.99 0-8.5-4.1-8.5-8.5z"
                fill="#b3d334"
            />
        </svg>
    )
})
