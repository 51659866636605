import { getPublicUrl } from '@teamflow/lib'
import { IconProps, IconComponent, Spritesheet } from '@teamflow/types'

import CalendarIcon from './CalendarIcon'
import GiftIcon from './GiftIcon'
import GoogleIcon from './GoogleIcon'
import GroupIcon from './GroupIcon'
import HuddleLogo from './HuddleLogo'
import InfoIconSVG from './InfoIconSVG'
import InviteEmptyTeamIcon from './InviteEmptyTeamIcon'
import MainFloorIcon from './MainFloor'
import MapIcon from './MapIcon'
import { MeetingRecordingIndicatorIcon } from './MeetingRecordingIndicatorIcon'
import MicBlockedIcon from './MicBlockedIcon'
import MicrophoneIcon from './MicrophoneIcon'
import OpenDoorIcon from './OpenDoor'
import PacketLossIcon from './PacketLossIcon'
import QualityThresholdIcon from './QualityThresholdIcon'
import SearchIcon from './SearchIcon'
import SettingsIcon from './SettingsIcon'
import VideoBlockedIcon from './VideoBlockedIcon'
import { SpriteSheetImage } from './components/SpriteSheetImage/SpriteSheetImage'
import DOCK_SPRITESHEET from './dockSpritesheet'
import SEARCH_SPRITESHEET from './searchSpritesheet'

export { default as AlertIcon } from './AlertIcon'
export { default as ApplicationTabPin } from './ApplicationTabPin'
export { default as Audio2Icon } from './Audio2Icon'
export { default as ImageAddIcon } from './ImageAddIcon'
export { default as HourglassIcon } from './HourglassIcon'
export { default as HuddleLogoIcon } from './HuddleLogo'
export { JustCallIcon } from './JustCallIcon'
export { default as PeopleIcon } from './PeopleIcon'
export { default as SlackIcon } from './SlackIcon'
export { default as SoundOnIcon } from './SoundOnIcon'
export { default as SpeakerIcon } from './SpeakerIcon'
export { default as OutreachLogo } from './salesfloor/OutreachLogo'
export { default as PhoneBurnerLogo } from './salesfloor/PhoneBurnerLogo'
export { default as JustCallLogo } from './salesfloor/JustCallLogo'
export { default as AircallLogo } from './salesfloor/AircallLogo'
export { default as RingCentralLogo } from './salesfloor/RingCentralLogo'
export { default as ZoomLogo } from './salesfloor/ZoomLogo'
export { default as SalesLoftLogo } from './salesfloor/SalesLoftLogo'
export { default as DialpadLogo } from './salesfloor/DialpadLogo'

// Generates a img-wrapping icon component, with an optional default dimensions.
function createImageIcon(
    src: string,
    dw?: number,
    dh?: number,
    borderRadius: number | undefined = undefined
): IconComponent {
    return ({ style, ...props }: IconProps) => {
        const styleToUse = borderRadius ? { ...style, borderRadius } : style
        return (
            <img
                draggable={'false'}
                src={src}
                width={props.width || (dw && `${dw}px`)}
                height={props.height || (dh && `${dh}px`)}
                style={styleToUse}
                {...props}
            />
        )
    }
}

export function createSpritesheetIcon(
    iconName: string,
    spritesheet: Spritesheet,
    dw?: number,
    dh?: number
): IconComponent {
    return ({
        width,
        height,
        ...props
    }: IconProps & JSX.IntrinsicAttributes) => (
        <SpriteSheetImage
            spriteSheet={spritesheet}
            name={iconName}
            width={width ?? dw}
            height={height ?? dh}
            {...props}
        />
    )
}

export const AlignLeftIcon = createImageIcon(
    getPublicUrl('/icons/align-left.svg')
)
export const BringForwardIcon = createImageIcon(
    getPublicUrl('/icons/bring-forward.svg')
)
export const CalibrateLocationIcon = createImageIcon(
    getPublicUrl('/icons/calibrate-location.svg'),
    16,
    16
)
export const ColorIcon = createImageIcon(
    getPublicUrl('/icons/color.svg'),
    16,
    16
)
export const EditIcon = createImageIcon(getPublicUrl('/icons/edit.svg'))
export const EllipsisIcon = createImageIcon(getPublicUrl('/icons/ellipsis.svg'))

export const FontSizeIcon = createImageIcon(
    getPublicUrl('/icons/font-size.svg'),
    24,
    24
)
export const FileEditIcon = createSpritesheetIcon(
    'scratchpad.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const InfoIcon = createImageIcon(getPublicUrl('/icons/info.svg'), 18, 18)
export const ImageUploadIcon = createSpritesheetIcon(
    'image.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const MapPinIcon = createImageIcon(getPublicUrl('/icons/map-pin.svg'))
export const LeaveRoomIcon = createSpritesheetIcon(
    'leave.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const HangupIcon = createSpritesheetIcon(
    'hangup.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const GoHomeIcon = createSpritesheetIcon(
    'home.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const PeopleOutlineIcon = createImageIcon(
    getPublicUrl('/icons/people-outline.svg')
)
export const RenameIcon = createImageIcon(
    getPublicUrl('/icons/rename.svg'),
    24,
    24
)
export const SendBackIcon = createImageIcon(
    getPublicUrl('/icons/send-back.svg')
)
export const ShareScreenIcon = createSpritesheetIcon(
    'screenshare-on.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const LeaveScreenShareIcon = createSpritesheetIcon(
    'screenshare-off.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const SharedBrowserIcon = createSpritesheetIcon(
    'shared-browser.png',
    DOCK_SPRITESHEET,
    48,
    48
)

export const EmbedIframeAppIcon = createImageIcon(
    getPublicUrl('/images/dock/embed-iframe-app.png'),
    48,
    48
)

export const StatusAcceptedIcon = createImageIcon(
    getPublicUrl('/icons/status-accepted.svg')
)
export const StatusDeclinedIcon = createImageIcon(
    getPublicUrl('/icons/status-declined.svg')
)
export const StatusTenativeIcon = createImageIcon(
    getPublicUrl('/icons/status-tenative.svg')
)
export const TimerIcon = createSpritesheetIcon(
    'timer.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const MoreDockIcon = createSpritesheetIcon(
    'more.png',
    DOCK_SPRITESHEET,
    48,
    48
)

export const WebBrowserIcon = createImageIcon(
    getPublicUrl('/icon/web-browser.svg')
)
export const WhiteboardIcon = createSpritesheetIcon(
    'whiteboard.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const ZoomInIcon = createImageIcon(getPublicUrl('/icons/zoom-in.svg'))
export const ZoomOutIcon = createImageIcon(getPublicUrl('/icons/zoom-out.svg'))
export const PokerIcon = createImageIcon(getPublicUrl('/icons/poker.svg'))
export const CodenamesIcon = createImageIcon(
    getPublicUrl('/icons/codenames.svg')
)
export const TetrisIcon = createImageIcon(getPublicUrl('/icons/jstris.svg'))

export const PollIcon = createImageIcon(getPublicUrl('/icons/poll.svg'))

export const GoogleDocsIcon = createImageIcon(
    getPublicUrl('/icons/googledocs.svg')
)
export const GoogleSheetsIcon = createImageIcon(
    getPublicUrl('/icons/googlesheets.svg')
)
export const GoogleSlidesIcon = createImageIcon(
    getPublicUrl('/icons/googleslides.svg')
)

export const GongIcon = createImageIcon(getPublicUrl('/icons/icon_gong.svg'))

export const TrelloIcon = createImageIcon(
    getPublicUrl('/icons/icon_trello.svg')
)

export const LeaderboardIcon = createImageIcon(
    getPublicUrl('/icons/icon_leaderboard.svg')
)

export const PiPIcon = createSpritesheetIcon(
    'pip-on.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const PiPIconOff = createSpritesheetIcon(
    'pip-off.png',
    DOCK_SPRITESHEET,
    48,
    48
)
export const BrowserNotificationsIcon = createImageIcon(
    getPublicUrl('/icons/notifications.png'),
    377,
    112
)
export const YouTubeIcon = createImageIcon(getPublicUrl('/icons/youtube.svg'))
export const YouTubeLogo = createImageIcon(
    getPublicUrl('/icons/youtube-logo.png'),
    300,
    67
)
export const SpotifyIcon = createImageIcon(getPublicUrl('/icons/spotify.svg'))
export const GiphyIcon = createImageIcon(getPublicUrl('/icons/giphy-icon.png'))
export const SmallEllipsisIcon = createImageIcon(
    getPublicUrl('/icons/ellipsis.svg'),
    12,
    12
)

export const GridModeIconCollapse = createSpritesheetIcon(
    'collapse.png',
    DOCK_SPRITESHEET,
    48,
    48
)

export const GridModeIconExpand = createSpritesheetIcon(
    'expand.png',
    DOCK_SPRITESHEET,
    48,
    48
)

export const DrawBattleIcon = createImageIcon(
    getPublicUrl('/icons/drawbattle.svg'),
    32,
    32
)
export const SplitModeIcon = createImageIcon(
    getPublicUrl('/images/splitMode.png'),
    40,
    24
)
export const GridModeIcon = createImageIcon(
    getPublicUrl('/images/gridMode.png'),
    40,
    24
)
export const ChatDockIcon = createSpritesheetIcon(
    'chat.png',
    DOCK_SPRITESHEET,
    48,
    48
)

export const TryOpeningApp = createImageIcon(
    getPublicUrl('/images/tryOpeningApp.png'),
    382,
    90
)

export const Microphone = createImageIcon(
    getPublicUrl('/icons/microphone-stage.png'),
    32,
    32
)

export const Tada = createImageIcon(getPublicUrl('/icons/tada.png'), 18, 18)

export const MegaphoneIcon = createImageIcon(
    getPublicUrl('/icons/megaphone.png'),
    20,
    20
)

export const WhisperIcon = createImageIcon(
    getPublicUrl('/icons/whisper-mode.png'),
    20,
    20
)

export const Options = createImageIcon(
    getPublicUrl('/icons/options.png'),
    24,
    24
)

export const Lock = createImageIcon(getPublicUrl('/icons/lock.png'), 24, 24)

export const Location = createImageIcon(
    getPublicUrl('/icons/location.png'),
    24,
    24
)

export const MeetingRecordingOffIcon = createSpritesheetIcon(
    'meeting-recording-off.png',
    DOCK_SPRITESHEET,
    18,
    18
)

export const MeetingRecordingOnIcon = createSpritesheetIcon(
    'meeting-recording-on.png',
    DOCK_SPRITESHEET,
    18,
    18
)
export const Schedule = createImageIcon(
    getPublicUrl('/icons/schedule.png'),
    32,
    32
)
export const NewMeeting = createImageIcon(
    getPublicUrl('/icons/newMeeting.png'),
    32,
    32
)
export const MainFloor = createImageIcon(
    getPublicUrl('/icons/mainFloor.png'),
    32,
    32
)
export const MyOffice = createImageIcon(
    getPublicUrl('/icons/myOffice.png'),
    32,
    32
)
export const HomeIcon = createImageIcon(getPublicUrl('/icons/home.svg'), 20, 20)
export const CloseIcon = createImageIcon(
    getPublicUrl('/icons/close.svg'),
    24,
    24
)

export const createFaviconIcon = (domain: string) => {
    const src = `https://www.google.com/s2/favicons?domain=${domain}&sz=128`
    return createImageIcon(src, 16, 16, 24)
}

export const MigrationIcon = createImageIcon(
    getPublicUrl('/icons/migration.svg'),
    64,
    64
)

export {
    CalendarIcon,
    GoogleIcon,
    HuddleLogo,
    InviteEmptyTeamIcon,
    MainFloorIcon,
    MapIcon,
    MicBlockedIcon,
    OpenDoorIcon,
    VideoBlockedIcon,
    SearchIcon,
    GiftIcon,
    InfoIconSVG,
    QualityThresholdIcon,
    PacketLossIcon,
    MeetingRecordingIndicatorIcon,
    SettingsIcon,
    MicrophoneIcon,
    GroupIcon,
    DOCK_SPRITESHEET,
    SEARCH_SPRITESHEET,
}
