import { IconProps } from '@teamflow/types'

export default function ImageAddIcon({ width, height, color }: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={color}>
                <path d="M13.187 4.045a1.493 1.493 0 100 2.986 1.493 1.493 0 000-2.986z" />
                <path d="M23.415 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.496.008.991.036 1.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z" />
                <path d="M13.831 9.4a.376.376 0 00-.633 0l-1.246 1.994a.244.244 0 00.042.308c.387.365.736.767 1.044 1.2a.241.241 0 00.2.1h7.08a.185.185 0 00.163-.096.185.185 0 00-.006-.19l-3.24-5.179a.373.373 0 00-.633 0l-1.81 2.9a.187.187 0 01-.317 0l-.644-1.036zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zM9 18.25H7.5a.25.25 0 00-.25.25V20a.75.75 0 11-1.5 0v-1.5a.25.25 0 00-.25-.25H4a.75.75 0 110-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 111.5 0v1.5a.25.25 0 00.25.25H9a.75.75 0 110 1.5z" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
