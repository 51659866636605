import { memo } from 'react'

import { tokens } from '@teamflow/design'
import { IconProps } from '@teamflow/types'

export default memo(function PeopleIcon({
    width = 20,
    height = 20,
    color = tokens.color.neutral60,
}: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.41667 17H3.925C3.97721 17 4.0275 17.0196 4.06593 17.055C4.10436 17.0903 4.12811 17.1388 4.1325 17.1908L4.5 21.6183C4.50879 21.7226 4.55655 21.8198 4.63375 21.8906C4.71096 21.9613 4.81196 22.0004 4.91667 22H8.25C8.3547 22.0004 8.4557 21.9613 8.53291 21.8906C8.61012 21.8198 8.65787 21.7226 8.66666 21.6183L9.03583 17.1908C9.04019 17.1391 9.06371 17.0908 9.10179 17.0555C9.13986 17.0202 9.18974 17.0004 9.24166 17H10.75C10.8605 17 10.9665 16.9561 11.0446 16.8779C11.1228 16.7998 11.1667 16.6938 11.1667 16.5833V13.6666C11.1667 12.4511 10.6838 11.2853 9.82424 10.4257C8.9647 9.5662 7.79891 9.08331 6.58333 9.08331C5.36776 9.08331 4.20197 9.5662 3.34243 10.4257C2.48289 11.2853 2 12.4511 2 13.6666L2 16.5833C2 16.6938 2.0439 16.7998 2.12204 16.8779C2.20018 16.9561 2.30616 17 2.41667 17Z"
                fill={color}
            />
            <path
                d="M6.58332 7.83333C8.19415 7.83333 9.49999 6.5275 9.49999 4.91667C9.49999 3.30584 8.19415 2 6.58332 2C4.97249 2 3.66666 3.30584 3.66666 4.91667C3.66666 6.5275 4.97249 7.83333 6.58332 7.83333Z"
                fill={color}
            />
            <path
                d="M22 13.6666C22 12.4511 21.5171 11.2853 20.6576 10.4257C19.798 9.5662 18.6323 9.08331 17.4167 9.08331C16.2011 9.08331 15.0353 9.5662 14.1758 10.4257C13.3162 11.2853 12.8333 12.4511 12.8333 13.6666V16.5833C12.8333 16.6938 12.8772 16.7998 12.9554 16.8779C13.0335 16.9561 13.1395 17 13.25 17H14.7583C14.8106 17 14.8608 17.0196 14.8993 17.055C14.9377 17.0903 14.9615 17.1388 14.9658 17.1908L15.3333 21.6183C15.3421 21.7226 15.3899 21.8198 15.4671 21.8906C15.5443 21.9613 15.6453 22.0004 15.75 22H19.0833C19.188 22.0004 19.289 21.9613 19.3663 21.8906C19.4435 21.8198 19.4912 21.7226 19.5 21.6183L19.8692 17.1908C19.8735 17.1391 19.8971 17.0908 19.9351 17.0555C19.9732 17.0202 20.0231 17.0004 20.075 17H21.5833C21.6938 17 21.7998 16.9561 21.878 16.8779C21.9561 16.7998 22 16.6938 22 16.5833V13.6666Z"
                fill={color}
            />
            <path
                d="M17.4167 7.83333C19.0275 7.83333 20.3333 6.5275 20.3333 4.91667C20.3333 3.30584 19.0275 2 17.4167 2C15.8058 2 14.5 3.30584 14.5 4.91667C14.5 6.5275 15.8058 7.83333 17.4167 7.83333Z"
                fill={color}
            />
            <path
                d="M2.41667 17H3.925C3.97721 17 4.0275 17.0196 4.06593 17.055C4.10436 17.0903 4.12811 17.1388 4.1325 17.1908L4.5 21.6183C4.50879 21.7226 4.55655 21.8198 4.63375 21.8906C4.71096 21.9613 4.81196 22.0004 4.91667 22H8.25C8.3547 22.0004 8.4557 21.9613 8.53291 21.8906C8.61012 21.8198 8.65787 21.7226 8.66666 21.6183L9.03583 17.1908C9.04019 17.1391 9.06371 17.0908 9.10179 17.0555C9.13986 17.0202 9.18974 17.0004 9.24166 17H10.75C10.8605 17 10.9665 16.9561 11.0446 16.8779C11.1228 16.7998 11.1667 16.6938 11.1667 16.5833V13.6666C11.1667 12.4511 10.6838 11.2853 9.82424 10.4257C8.9647 9.5662 7.79891 9.08331 6.58333 9.08331C5.36776 9.08331 4.20197 9.5662 3.34243 10.4257C2.48289 11.2853 2 12.4511 2 13.6666L2 16.5833C2 16.6938 2.0439 16.7998 2.12204 16.8779C2.20018 16.9561 2.30616 17 2.41667 17Z"
                fill={color}
            />
            <path
                d="M6.58332 7.83333C8.19415 7.83333 9.49999 6.5275 9.49999 4.91667C9.49999 3.30584 8.19415 2 6.58332 2C4.97249 2 3.66666 3.30584 3.66666 4.91667C3.66666 6.5275 4.97249 7.83333 6.58332 7.83333Z"
                fill={color}
            />
            <path
                d="M22 13.6666C22 12.4511 21.5171 11.2853 20.6576 10.4257C19.798 9.5662 18.6323 9.08331 17.4167 9.08331C16.2011 9.08331 15.0353 9.5662 14.1758 10.4257C13.3162 11.2853 12.8333 12.4511 12.8333 13.6666V16.5833C12.8333 16.6938 12.8772 16.7998 12.9554 16.8779C13.0335 16.9561 13.1395 17 13.25 17H14.7583C14.8106 17 14.8608 17.0196 14.8993 17.055C14.9377 17.0903 14.9615 17.1388 14.9658 17.1908L15.3333 21.6183C15.3421 21.7226 15.3899 21.8198 15.4671 21.8906C15.5443 21.9613 15.6453 22.0004 15.75 22H19.0833C19.188 22.0004 19.289 21.9613 19.3663 21.8906C19.4435 21.8198 19.4912 21.7226 19.5 21.6183L19.8692 17.1908C19.8735 17.1391 19.8971 17.0908 19.9351 17.0555C19.9732 17.0202 20.0231 17.0004 20.075 17H21.5833C21.6938 17 21.7998 16.9561 21.878 16.8779C21.9561 16.7998 22 16.6938 22 16.5833V13.6666Z"
                fill={color}
            />
            <path
                d="M17.4167 7.83333C19.0275 7.83333 20.3333 6.5275 20.3333 4.91667C20.3333 3.30584 19.0275 2 17.4167 2C15.8058 2 14.5 3.30584 14.5 4.91667C14.5 6.5275 15.8058 7.83333 17.4167 7.83333Z"
                fill={color}
            />
        </svg>
    )
})
