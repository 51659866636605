import { memo } from 'react'

export default memo(function ApplicationTabPin() {
    return (
        <svg
            width="36"
            height="36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#a)" filter="url(#b)">
                <path
                    d="m20.894 7.894.02-.02a.15.15 0 0 1 .205.035l2.75 3.75h.001a.15.15 0 0 1-.21.21l-3.75-2.748a.15.15 0 0 1-.06-.11.145.145 0 0 1 .043-.117l1-1Z"
                    fill="#fff"
                    stroke="#DFDFDF"
                    strokeWidth=".2"
                />
                <path
                    d="m19.073 4.573-2.5 2.5c-.327.42-.493.944-.467 1.477a2.58 2.58 0 0 0 .717 1.877c.406.406 1.034.364 1.866-.125a10.946 10.946 0 0 0 1.988-1.625c.994-.995 2.026-2.25 2.026-3.165a.936.936 0 0 0-.276-.688c-1.04-1.041-2.822-.782-3.354-.251Z"
                    fill="#D32F2F"
                />
                <path
                    d="m17.073 2.573-2.5 2.5a.25.25 0 0 0 0 .354l2 2c.327.326 1.05.3 2.104-.75.685-.686.935-1.232.935-1.62a.663.663 0 0 0-.185-.484l-2-2a.25.25 0 0 0-.354 0Z"
                    fill="#F44336"
                />
                <path
                    d="M16.405.02a.25.25 0 0 0-.155.23c0 .542-.586 1.732-1.427 2.573-.84.841-2.03 1.427-2.573 1.427a.25.25 0 0 0-.177.427c1.022 1.021 2.764 1.34 4.354-.25a3.343 3.343 0 0 0 1.104-2.36 2.828 2.828 0 0 0-.854-1.993.25.25 0 0 0-.272-.055Z"
                    fill="#D32F2F"
                />
                <path
                    d="M13.573 1.573C12.515 2.633 12 3.59 12 4.5c0 .138.112.25.25.25.747 0 2.06-.706 2.927-1.573C16.045 2.31 16.75.997 16.75.25A.25.25 0 0 0 16.5 0c-.91 0-1.868.515-2.927 1.573Z"
                    fill="#F44336"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill="#fff"
                        transform="rotate(-90 12 0)"
                        d="M0 0h12v12H0z"
                    />
                </clipPath>
                <filter
                    id="b"
                    x="0"
                    y="0"
                    width="36"
                    height="36"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="4"
                        in="SourceAlpha"
                        result="effect1_dropShadow_246_12307"
                    />
                    <feOffset dy="12" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix values="0 0 0 0 0.054902 0 0 0 0 0.0901961 0 0 0 0 0.152941 0 0 0 0.1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_246_12307"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="2"
                        in="SourceAlpha"
                        result="effect2_dropShadow_246_12307"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix values="0 0 0 0 0.054902 0 0 0 0 0.0901961 0 0 0 0 0.152941 0 0 0 0.06 0" />
                    <feBlend
                        in2="effect1_dropShadow_246_12307"
                        result="effect2_dropShadow_246_12307"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_246_12307"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
})
