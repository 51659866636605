import { memo } from 'react'

import { IconComponent, IconProps } from '@teamflow/types'

export default memo(function SpeakerIcon({ width, height }: IconProps) {
    return (
        <svg
            width={`${width ?? 64}px`}
            height={`${height ?? 52}px`}
            viewBox="0 0 64 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.0193 45.9502C32.0193 47.0777 31.3678 48.1019 30.3484 48.58C29.9542 48.7664 29.5318 48.8566 29.1122 48.8566C28.4477 48.8566 27.7894 48.6282 27.2577 48.1872L11.3688 35.021H2.90634C1.30149 35.0224 0 33.7202 0 32.1154V20.5361C0 18.9305 1.30149 17.6291 2.90634 17.6291H11.3695L27.2584 4.46282C28.1265 3.74329 29.3303 3.58989 30.3491 4.07141C31.3678 4.5495 32.0199 5.57445 32.0199 6.70122L32.0193 45.9502ZM43.1885 41.4088C43.1177 41.4136 43.0496 41.4163 42.9794 41.4163C42.2117 41.4163 41.4716 41.113 40.9247 40.5654L40.536 40.1754C39.5166 39.158 39.3969 37.5463 40.2554 36.3892C42.4319 33.4547 43.5806 29.976 43.5806 26.3267C43.5806 22.4016 42.2764 18.7201 39.8082 15.6796C38.8686 14.5239 38.9553 12.8455 40.0084 11.793L40.3964 11.4043C40.977 10.8237 41.755 10.5073 42.597 10.5575C43.4169 10.5988 44.1825 10.9847 44.7026 11.6203C48.1262 15.8096 49.9347 20.8958 49.9347 26.3274C49.9347 31.3862 48.3353 36.2014 45.3086 40.2504C44.803 40.9252 44.0298 41.3489 43.1885 41.4088ZM55.2046 50.3905C54.6791 51.0117 53.919 51.3846 53.1052 51.4189C53.0653 51.4203 53.0247 51.4217 52.9834 51.4217C52.2137 51.4217 51.4749 51.1176 50.928 50.5708L50.5462 50.189C49.4793 49.1228 49.4071 47.4175 50.377 46.2632C55.0643 40.6885 57.6466 33.6088 57.6466 26.3267C57.6466 18.7524 54.882 11.4607 49.8638 5.79527C48.8464 4.64511 48.898 2.90406 49.9808 1.81719L50.3619 1.43541C50.9273 0.867216 51.662 0.554226 52.5047 0.581054C53.3047 0.603754 54.0613 0.95733 54.5931 1.5558C60.6589 8.38518 64 17.1833 64 26.3267C64.0014 35.1242 60.8777 43.6705 55.2046 50.3905Z"
                fill="black"
            />
        </svg>
    )
}) as IconComponent
