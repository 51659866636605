import { CSSProperties, useMemo } from 'react'
import { getDevicePixelRatio } from 'use-device-pixel-ratio'

import { getPublicUrl } from '@teamflow/lib'
import { Spritesheet } from '@teamflow/types'

import * as styles from './SpriteSheetImage.css'

export interface SpriteSheetImageProps {
    width?: number
    height?: number
    color?: string
    style?: CSSProperties
    spriteSheet: Spritesheet
    name: string
}

export function SpriteSheetImage({
    spriteSheet,
    name,
    width = 0,
    height = 0,
    style,
}: SpriteSheetImageProps): JSX.Element {
    const pixelRatio = (getDevicePixelRatio() + 'x') as keyof Spritesheet
    const scaledSpriteSheet =
        spriteSheet[pixelRatio] ??
        spriteSheet['3x'] ??
        spriteSheet['2x'] ??
        spriteSheet['1x']

    if (!scaledSpriteSheet) {
        throw new Error(`Missing sprite sheet for pixel ratio: ${pixelRatio}`)
    }

    const icon = scaledSpriteSheet.frames[name]

    if (!icon) {
        throw new Error(`Missing sprite sheet image: ${name}`)
    }

    const scale = width
        ? width / icon.frame.w
        : height
        ? height / icon.frame.h
        : 1

    let assetUrl = getPublicUrl(scaledSpriteSheet.meta.image)

    if (process.env.NEXT_PUBLIC_RELEASE_GLOBAL_CACHE_BURSTING_VALUE) {
        assetUrl = `${assetUrl}?v=${process.env.NEXT_PUBLIC_RELEASE_GLOBAL_CACHE_BURSTING_VALUE}`
    }

    const containerStyles = useMemo(
        () => ({
            width: width || icon.frame.w,
            height: height || icon.frame.h,
        }),
        [width, height, icon.frame.w, icon.frame.h]
    )

    const imageStyles = useMemo(
        () => ({
            backgroundImage: `url(${assetUrl})`,
            backgroundPosition: `-${icon.frame.x}px -${icon.frame.y}px`,
            width: icon.frame.w,
            height: icon.frame.h,
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            ...style,
        }),
        [
            assetUrl,
            icon.frame.h,
            icon.frame.w,
            icon.frame.x,
            icon.frame.y,
            scale,
            style,
        ]
    )

    return (
        <div style={containerStyles}>
            <div className={styles.crispImageBox} style={imageStyles} />
        </div>
    )
}
