import { memo } from 'react'

import { QualityThreshold } from '@teamflow/types'

interface Props {
    qualityThreshold: QualityThreshold
    size?: number
}

const COLORS: Record<QualityThreshold, string> = {
    [QualityThreshold.LOW]: '#EB5757',
    [QualityThreshold.MEDIUM]: '#F39D50',
    [QualityThreshold.HIGH]: '#00B894',
    [QualityThreshold.UNKNOWN]: '#A4AEB1',
}

export default memo(function QualityThresholdIcon({
    qualityThreshold,
    size = 12,
}: Props) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="6"
                width="2"
                height="6"
                rx="0.5"
                fill={COLORS[qualityThreshold]}
            />
            <rect
                x="5"
                y="4"
                width="2"
                height="8"
                rx="0.5"
                fill={
                    qualityThreshold >= QualityThreshold.MEDIUM
                        ? COLORS[qualityThreshold]
                        : COLORS[QualityThreshold.UNKNOWN]
                }
            />
            <rect
                x="9"
                y="2"
                width="2"
                height="10"
                rx="0.5"
                fill={
                    qualityThreshold >= QualityThreshold.HIGH
                        ? COLORS[qualityThreshold]
                        : COLORS[QualityThreshold.UNKNOWN]
                }
            />
        </svg>
    )
})
