import { memo } from 'react'

export default memo(function OutreachLogo({
    width = 100,
    height = 100,
}: {
    width?: number | string
    height?: number | string
}) {
    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
        >
            <path
                d="M44.51 0C59.72 0 82.7 5 93.4 16.48c2.71 2.91 6.26 8.61 6.26 16.96 0 14.84-11.15 37.61-23.45 50.82C69.84 91.08 59.72 100 46.11 100c-13.31 0-23.47-9.7-26.42-12.86C8.42 75.05.34 56.7.34 39.58c0-10.8 2.87-20.25 10.19-28.1C17.31 4.21 29 0 44.51 0zm18.83 59.18c5.14-5.51 6.52-12.3 6.52-16.03 0-2.71-.66-6.68-3.82-10.07-4.84-5.2-10.21-6.66-16.04-6.66-7.39 0-12.36 2.51-15.66 6.06-2.59 2.78-4.84 7.06-4.84 13.14 0 6.08 3.02 11.94 6.31 15.47 3.04 3.26 7.82 5.67 12.63 5.67 4.79 0 9.76-2.07 14.9-7.58z"
                fill="#5952FF"
            />
        </svg>
    )
})
